




import Vue from 'vue';
import { CompositionRoot } from './CompositionRoot';
import { IMetadataService } from '../../shared/src/services/IMetadataService';
import { ISettingsDataservice } from '../../shared/src/dataservices/ISettingsDataservice';
import { IContactDataservice } from '../../shared/src/dataservices/IContactDataservice';
import { ICategoryDataservice } from '../../shared/src/dataservices/ICategoryDataservice';
import { IProductDataservice } from '../../shared/src/dataservices/IProductDataservice';
import { IImageDataservice } from '../../shared/src/dataservices/IImageDataservice';
import { IUserService } from '../../shared/src/services/IUserService';
import { IUUIDHelperService } from '../../shared/src/services/IUUIDHelperService';

export default Vue.extend({
  name: 'App',
  provide: {
    settingsDataservice: Vue.observable(CompositionRoot.getInstance().settingsDataservice) as ISettingsDataservice,
    contactDataservice: Vue.observable(CompositionRoot.getInstance().contactDataservice) as IContactDataservice,
    categoryDataservice: Vue.observable(CompositionRoot.getInstance().categoryDataservice) as ICategoryDataservice,
    productDataservice: Vue.observable(CompositionRoot.getInstance().productDataservice) as IProductDataservice,
    metadataService: Vue.observable(CompositionRoot.getInstance().metadataService) as IMetadataService,
    imageDataservice: Vue.observable(CompositionRoot.getInstance().imageDataservice) as IImageDataservice,
    userService: Vue.observable(CompositionRoot.getInstance().userService) as IUserService,
    uuidHelperService: Vue.observable(CompositionRoot.getInstance().uuidHelperService) as IUUIDHelperService
  },
  data: () => ({
    metadataService: CompositionRoot.getInstance().metadataService as IMetadataService,
    settingsDataservice: CompositionRoot.getInstance().settingsDataservice as ISettingsDataservice
  }),
  computed: {
    faviconUrl(): string {
      return this.settingsDataservice.faviconUrl;
    },
    touchIconUrl(): string {
      return this.settingsDataservice.touchIconUrl;
    },
  },
  watch: {
    faviconUrl: {
      immediate: true,
      handler(newValue): void {
        this.metadataService.setFaviconUrl(newValue);
      }
    },
    touchIconUrl: {
      immediate: true,
      handler(newValue): void {
        this.metadataService.setTouchIcon(newValue);
      },
    }
  },
});
