import { IUserService } from './IUserService';

export class UserService implements IUserService {

    private _auth: firebase.default.auth.Auth;
    private _user?: firebase.default.User | null;

    constructor(auth: firebase.default.auth.Auth) {
        this._auth = auth;
        this.initializeUser();
    }

    public get isUserAuthorized(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const unsubscribe = this._auth.onAuthStateChanged((user) => {
                unsubscribe();
                if (user) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    public signIn(email: string, password: string): Promise<boolean>  {
        return this._auth.signInWithEmailAndPassword( email, password).then( (userCredential) => {
            return new Promise<boolean>((resolve) => {
                this._user = userCredential.user;
                this.initializeUser();
                resolve(this._user !== null );
            });
        }).catch((e) => {
            return new Promise<boolean>((resolve) => {
                resolve(false);
            });
        });
    }

    public logout(): Promise<boolean> {
        return this._auth.signOut().then(() => {
            return new Promise<boolean>((resolve) => {
                resolve(true);
            });
        }).catch(() => {
            return new Promise<boolean>((resolve) => {
                resolve(false);
            });
        });
    }

    private initializeUser(): void {
        this._auth.onAuthStateChanged((user: any) => {
            if (user) {
                this._user = user;
            } else {
                this._user = null;
            }
        });
    }
}
