import { ICategoryDataservice} from '../../shared/src/dataservices/ICategoryDataservice';
import { CategoryDataService } from '../../shared/src/dataservices/CategoryDataservice';
import { Firebase } from './plugins/Firebase';
import { ICompositionRoot } from './ICompositionRoot';
import { IImageDataservice } from '../../shared/src/dataservices/IImageDataservice';
import { ImageDataservice } from '../../shared/src/dataservices/ImageDataservice';
import { IUserService } from '../../shared/src/services/IUserService';
import { UserService } from '../../shared/src/services/UserService';
import { ISettingsDataservice } from '../../shared/src/dataservices/ISettingsDataservice';
import { SettingsDataservice } from '../../shared/src/dataservices/SettingsDataservice';
import { IUUIDHelperService } from '../../shared/src/services/IUUIDHelperService';
import { UUIDHelperService } from '../../shared/src/services/UUIDHelperService';
import { IProductDataservice } from '../../shared/src/dataservices/IProductDataservice';
import { ProductDataService } from '../../shared/src/dataservices/ProductDataservice';
import { StringUtilities } from '../../shared/src/services/StringUtilities';
import { IStringUtilities } from '../../shared/src/services/IStringUtilities';
import { IMetadataService } from '../../shared/src/services/IMetadataService';
import { MetadataService } from '../../shared/src/services/MetadataService';
import { IContactDataservice } from '../../shared/src/dataservices/IContactDataservice';
import { ContactDataservice } from '../../shared/src/dataservices/ContactDataservice';

export class CompositionRoot implements ICompositionRoot {

    public static getInstance(): ICompositionRoot {
        if (!CompositionRoot.instance) {
            CompositionRoot.instance = new CompositionRoot();
        }

        return CompositionRoot.instance;
    }

    private static instance: ICompositionRoot;
    private _categoryDataservice: ICategoryDataservice;
    private _imageDataservice: IImageDataservice;
    private _userService: IUserService;
    private _settingsDataservice: ISettingsDataservice;
    private _uuidHelperService: IUUIDHelperService;
    private _productDataservice: IProductDataservice;
    private _stringUtilities: IStringUtilities;
    private _metadataService: IMetadataService;
    private _contactDataservice: IContactDataservice;

    private constructor() {
        const fireBase = new Firebase();
        this._uuidHelperService = new UUIDHelperService();
        this._stringUtilities = new StringUtilities();
        this._imageDataservice = new ImageDataservice(fireBase.storage, this._uuidHelperService);
        this._settingsDataservice = new SettingsDataservice(fireBase.firestore, this.imageDataservice);
        this._metadataService = new MetadataService(this._settingsDataservice);
        this._categoryDataservice = new CategoryDataService(
            fireBase.firestore,
            this._imageDataservice,
            this._stringUtilities);
        this._userService = new UserService(fireBase.auth);
        this._contactDataservice = new ContactDataservice(fireBase.firestore, this.imageDataservice);
        this._productDataservice = new ProductDataService(
            fireBase.firestore,
            this._imageDataservice,
            this._stringUtilities,
            this._categoryDataservice);
    }

    public get categoryDataservice(): ICategoryDataservice {
        return this._categoryDataservice;
    }

    public get productDataservice(): IProductDataservice {
        return this._productDataservice;
    }

    public get imageDataservice(): IImageDataservice {
        return this._imageDataservice;
    }

    public get userService(): IUserService {
        return this._userService;
    }

    public get settingsDataservice(): ISettingsDataservice {
        return this._settingsDataservice;
    }

    public get uuidHelperService(): IUUIDHelperService {
        return this._uuidHelperService;
    }

    public get metadataService(): IMetadataService {
        return this._metadataService;
    }

    public get contactDataservice(): IContactDataservice {
        return this._contactDataservice;
    }
  }

