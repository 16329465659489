import Vue from 'vue';
import Router from 'vue-router';
import { CompositionRoot } from './CompositionRoot';

Vue.use(Router);

const compositionRoot = CompositionRoot.getInstance();

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '*', redirect: '/' },
    { path: '/', redirect: { name: 'Frontpage' } },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {
        noAuth: true
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
      children: [
        {
          path: '/categories',
          name: 'Categories',
          component: () => import('./views/Categories.vue'),
        },
        {
          path: '/categories/:id',
          name: 'Category',
          component: () => import('./views/Category.vue'),
        },
        {
          path: '/products',
          name: 'Products',
          component: () => import('./views/Products.vue'),
        },
        {
          path: '/products/:id',
          name: 'Product',
          component: () => import('./views/Product.vue'),
        },
        {
          path: '/general',
          name: 'General',
          component: () => import('./views/General.vue'),
        },
        {
          path: '/contact',
          name: 'Contact',
          component: () => import('./views/Contact.vue'),
        },
        {
          path: '/frontpage',
          name: 'Frontpage',
          component: () => import('./views/Frontpage.vue'),
        },
    ]
  }],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = !to.matched.some((record) => record.meta.noAuth);

  if (!requiresAuth) {
    next();
    return;
  }

  compositionRoot.userService.isUserAuthorized.then(
    (isAuthorized) => {
      if (isAuthorized) {
        next();
      } else {
        next('login');
      }
    }
  );
});

export default router;
